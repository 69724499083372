<template>
  <a-card :bordered="false" class="card-area">
    <a-form layout="horizontal">
      <span class="fold">
        <a-row>
          <a-col :md="6" :sm="6">
            <a-form-item
                label="发布人"
                :labelCol="{span: 4}"
                :wrapperCol="{span: 12, offset: 1}">
              <a-input v-model.trim="queryParams.username"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="6">
            <a-form-item
                label="订单号"
                :labelCol="{span: 4}"
                :wrapperCol="{span: 12, offset: 1}">
              <a-input v-model.trim="queryParams.billno"/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item
                label="服务状态"
                :labelCol="{span: 2}"
                :wrapperCol="{span: 3, offset: 1}">
              <a-select v-model="queryParams.status" placeholder="选择过滤字段" style="width:100%"
                        @change="searchTable()">
                <a-select-option v-for="item in inagencyNStatus" :value="item.value"
                                 :key="item.value">{{ item.label }}
                </a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
        </a-row>
      </span>
      <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="searchTable()">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset()">重置</a-button>
      </span>
    </a-form>
    <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
             :scroll="{ x: 1000 }">
      <template slot="imghead" slot-scope="text">
        <div v-if="text.uhead" class="img-list">
          <el-image style="width: 40px; height: 40px" :src="(text.uhead || '') | fullImgPath" fit="contain"
                    :preview-src-list="(text.uhead || '').split(',')"/>
        </div>
      </template>
      <template slot="status" slot-scope="text,record">
        <a-tag :color="record.status | dictName('inagencyNStatus', 'color')">
          {{ record.status | dictName("inagencyNStatus") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" @click="linkOrder(record)">相关订单</a-button>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import {DICT} from "@/utils/dict";

export default {
  name: "inagencyReader",
  data() {
    return {
      tableData: [],
      value: '',
      queryParams: {
        fwhere: "2",
        username: '',
        billno: '',
        status: undefined
      }
    };
  },
  created() {
    this.searchTable();
  },
  computed: {
    inagencyNStatus() {
      return DICT['inagencyNStatus']
    },
    columns() {
      return [{
        title: "ID",
        width: 50,
        align: "center",
        dataIndex: "id",
      }, {
        title: "发布人",
        width: 80,
        align: "center",
        dataIndex: "username",
      }, {
        title: "服务状态",
        width: 80,
        align: "center",
        dataIndex: "status",
        scopedSlots: {customRender: "status"},
      }, {
        title: "订单号",
        width: 120,
        align: "center",
        dataIndex: "billno",
      }, {
        title: "情报费",
        width: 80,
        align: "center",
        dataIndex: "amg",
      }, {
        title: "关联公司",
        width: 120,
        align: "center",
        dataIndex: "standardName",
      }, {
        title: "需求说明",
        width: 120,
        align: "center",
        dataIndex: "mark",
      }, {
        title: "有效期",
        width: 80,
        align: "center",
        dataIndex: "vdate",
      }, {
        title: "创建日期",
        width: 80,
        align: "center",
        dataIndex: "createdate",
      }, {
        title: '操作',
        width: 160,
        align: 'center',
        dataIndex: 'operation',
        scopedSlots: {customRender: 'operation'}
      }];
    }
  },
  methods: {
    linkOrder(row) {
      this.$router.push({path: '/orders/readerList', query: {id: row.id}})
    },
    reset() {
      this.queryParams = {
        fwhere: "2",
        username: '',
        orderNo: '',
        status: undefined
      }
    },
    searchTable() {
      let that = this;
      that.showLoading = true;
      that.$postJson("jobIntro/readerList", this.queryParams).then((res) => {
        that.showLoading = false;
        that.tableData = res.data;
      });
    }
  }
}
</script>
<style>
.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
